var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "two-thirds" } },
                [
                  _c("router-view", {
                    attrs: { errors: _vm.form.$errors },
                    on: { completed: _vm.submitRegistration },
                    model: {
                      value: _vm.form,
                      callback: function($$v) {
                        _vm.form = $$v
                      },
                      expression: "form"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }